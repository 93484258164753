import React, {useEffect, useState} from 'react'

// import { boxData as data } from './HomeBoxesData';
import useTranslate from '../../../hooks/useTranslate';
import Intl from '../../Intl';
import Techimg from '../../../assets/images/carusol/tech2.jpeg';
import Resimg from '../../../assets/images/desktop04.jpg'
import Secimg from '../../../assets/images/desktop10.jpg'
import Cloudimg from '../../../assets/images/desktop11.jpg'
import ITimg from '../../../assets/images/carusol/tech7.jpeg'

function HomeBoxes() {
  const [translate] = useTranslate();
  const data = translate('boxData', { isArray: true });
  const [boxData, setBoxData] = useState(data[0]);
  const [imgSrc, setImgSrc] = useState(Techimg);
  
  function setValue(value){
    if(value === "Tech"){
      setBoxData(data[0]);
      setImgSrc(Techimg);
    }else if(value === "Research"){
      setBoxData(data[1]);
      setImgSrc(Resimg);
    }else if(value === "Security"){
      setBoxData(data[2]);
      setImgSrc(Secimg);
    }else if(value === "Cloud"){
      setBoxData(data[3]);
      setImgSrc(Cloudimg);
    }else if(value === "IT"){
      setBoxData(data[4]);
      setImgSrc(ITimg);
    }
  }

  
  return (
    <>

        <div className='test-center items-center p-10 mt-8 bg-gray-700'>

          <nav className=" flex text-base justify-center font-sans text-right text-white space-x-6  md:flex">
                          <div  className="hover:text-gray-900 text-avermass-black-600 text-xl font-sans cursor-pointer" onClick={() => setValue("Tech")}><Intl id="technology"></Intl></div> 
                          <div className="hover:text-gray-900 font-sans text-xl cursor-pointer"  onClick={() => setValue("Research")} ><Intl id="research"></Intl></div> 
                          <div className="hover:text-gray-900 font-sans text-xl cursor-pointer" onClick={() => setValue("Security")}><Intl id="security"></Intl></div> 
                          <div className="hover:text-gray-900 font-sans text-xl cursor-pointer" onClick={() => setValue("Cloud")}><Intl id="cloud"></Intl></div>
                          <div className="hover:text-gray-900 font-sans text-xl cursor-pointer" onClick={() => setValue("IT")}><Intl id="mlaiAutomation"></Intl></div> 
          </nav>

        </div>

        <section className="text-white ">

                <div className="mt-10 mx-auto  flex container  md:flex-row md:flex-nowrap flex-wrap flex-col shadow-2xl">

                        <div className="flex flex-col flex-wrap lg:py-6  lg:w-1/2 lg:pl-12 lg:text-left text-center">
                            <h3 className="text-2xl font-bold text-white mb-4">{boxData?.title}</h3>
                            <p className="text-gray-400 mb-5 flex">{boxData?.subTitle}</p>
                            <div className="flex mb-10 lg:items-start items-center">
                                <p className='p-4 flex '>
                                  {boxData?.body}
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 overflow-hidden md:w-full mx-auto shadow-2xl">
                            <img alt="feature" className="object-cover object-center h-3/4 w-full" src={imgSrc} />
                        </div>
                </div>
            </section>

    </>
  )
}

export default HomeBoxes
import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import HeaderTop from '../components/Header/HeaderTop'
import HomecardsPage from '../components/HomePage/HomeCards/Homecards'
import HomeBoxesPage from '../components/HomePage/HomeBoxes/HomeBoxes'
import HomeAboutCompany from '../components/HomePage/HomeAboutCompany/HomeAboutCompany'
import HomeCustomer from '../components/HomePage/HomeCustomer/HomeCustomer'
import Carousel from '../components/Core/Carousel/Carousel'
import { CarouselData as data } from '../components/Core/Carousel/CarouselData'

const IndexPage = () => {

    return (
        <>
            <HeaderTop />
            <Header />

            <Carousel
                data={data}
                slide={true}
                delay={4000}
                
            />

            <section className="bg-indigo-900 pl-4">
                <HomecardsPage />
            </section>


            <section className="bg-gray-900 text-white ">                
                <HomeAboutCompany />
            </section>


            <section >                
                <HomeBoxesPage />                
            </section>


            <section className='m-28'>
                <HomeCustomer />
            </section>


            <Footer />

        </>
    )
}

export default IndexPage


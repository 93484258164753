import React from 'react'
import { Link } from 'gatsby'


import ffd from '../../../assets/images/FaceForgeryDetection.png'
import fsd from '../../../assets/images/SignatureForgeryDetection.jpg'
import fnd from '../../../assets/images/FakeNewsDetection.png'
import efd from '../../../assets/images/EndToEndForgeryDetection.png'
import smi from '../../../assets/images/ScrumMaster.jpg'
import textImg from '../../../assets/images/textForgeryDetection.jpg'
import Intl from '../../Intl'


function Homecards() {
    return (
        <>
            <div>
                    <h3 className="text-4xl  text-white p-8 font-thin"><Intl id="whatwe"></Intl> <span className="text-avermass-blue-500"><Intl id="offer"></Intl></span></h3>

                    <p className="mx-auto text-gray-400 w-11/12">
                            <Intl id="OffersExplanation"></Intl>
                    </p>
            </div>

            <div className="container mx-auto lg:grid grid-cols-3 gap-2 py-24 md:items-center">
                                    
                    <div className="max-w-sm rounded overflow-hidden cursor-pointer shadow-2xl transition ease-in-out delay-300 hover:-translate-y-20 hover:scale-800 duration-500 ...">
                        <img className="w-full cursor-pointer scale-100 transform-gpu hover:transition-all" src={ffd} alt="Face Forgery Detection" />
                        <div className="px-6 py-4">
                            <div className="font-bold text-light-blue-200 text-xl mb-2"><Intl id="FaceForgeryDetection"></Intl></div>
                            <p className="text-gray-300 text-base">
                                <Intl id="FaceForgeryDetectionExplanation" />

                            </p>
                        </div>
                        <div className="px-6 pt-4 pb-2">
                            <Link to='#' className="mt-3 text-blue-500 inline-flex items-center "><Intl id="formore"></Intl>
                                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                                        </svg>
                            </Link>
                        </div>
                    </div>



                    <div className="max-w-sm rounded overflow-hidden shadow-2xl">
                        <img className="w-full" src={fsd} alt="SignatureForgeryDetection" />
                        <div className="px-6 py-4">
                            <div className="font-bold text-light-blue-200 text-xl mb-2"><Intl id="SignatureForgeryDetection"></Intl></div>
                            <p className="text-gray-300 text-base">
                            <Intl id="SignatureForgeryDetectionExplanation" />
                                

                            </p>
                        </div>
                        <div className="px-6 pt-4 pb-2">
                                <Link to='#' className="mt-3 text-blue-500 inline-flex items-center"><Intl id="formore"></Intl>
                                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                                        </svg>
                                </Link>
                        </div>
                    </div>


                    <div className="max-w-sm rounded overflow-hidden shadow-2xl">
                        {/* <span className="bg-indigo-500 text-white px-3 py-1 tracking-widest text-xs absolute rounded-bl">
                            <Intl id="comingsoon"></Intl>
                        </span> */}
                        <img className="w-full bg-cover bg-center" src={textImg} alt="Text Forgery Detection" />
                        <div className="px-6 py-4">
                            <div className="font-bold text-light-blue-200 text-xl mb-2"><Intl id="textForgeryDetection"></Intl></div>
                            <p className="text-gray-300 text-base">
                            <Intl id="textForgeryDetectionExplanation" />
                            </p>
                        </div>
                        <div className="px-6 pt-4 pb-2">
                            <Link to='#' className="mt-3 text-blue-500 inline-flex items-center"><Intl id="formore"></Intl>
                                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                                        </svg>
                            </Link>
                        </div>
                    </div>


                    
                    <div className="max-w-sm rounded overflow-hidden shadow-2xl mt-6">
                        <span className="bg-indigo-500 text-white px-3 py-1 tracking-widest text-xs absolute rounded-bl">
                            <Intl id="comingsoon"></Intl>
                        </span>
                        <img className="w-full bg-cover bg-center" src={smi} alt="E2E Forgery Detection" />
                        <div className="px-6 py-4">
                            <div className="font-bold text-light-blue-200 text-xl mb-2"><Intl id="ScrumMaster"></Intl></div>
                            <p className="text-gray-300 text-base">
                            <Intl id="ScrumMasterExplanation" />
                            </p>
                        </div>
                        <div className="px-6 pt-4 pb-2">
                            <Link to='#' className="mt-3 text-blue-500 inline-flex items-center"><Intl id="formore"></Intl>
                                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                                        </svg>
                            </Link>
                        </div>
                    </div>

                    
                    
                    <div className="max-w-sm rounded overflow-hidden shadow-2xl mt-6">
                        <span className="bg-indigo-500 text-white px-3 py-1 tracking-widest text-xs absolute rounded-bl z-10">
                            <Intl id="comingsoon"></Intl>
                        </span>
                        <img className="w-full cursor-pointer scale-100 transform-gpu hover:transition-all" src={fnd} alt="Fake News Detections" />
                        <div className="px-6 py-4">
                            <div className="font-bold text-light-blue-200 text-xl mb-2"><Intl id="FakeNewsDetection"></Intl></div>
                            <p className="text-gray-300 text-base">
                            <Intl id="FakeNewsDetectionExplanation" />

                            </p>
                        </div>
                        <div className="px-6 pt-4 pb-2">
                            <Link to='#' className="mt-3 text-blue-500 inline-flex items-center "><Intl id="formore"></Intl>
                                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                                        </svg>
                            </Link>
                        </div>
                    </div>

                    <div className="max-w-sm rounded overflow-hidden shadow-2xl mt-6">
                        <span className="bg-indigo-500 text-white px-3 py-1 tracking-widest text-xs absolute rounded-bl">
                        <Intl id="comingsoon"></Intl>
                        </span>
                        <img className="w-full bg-cover bg-center" src={efd} alt="E2E Forgery Detection" />
                        <div className="px-6 py-4">
                            <div className="font-bold text-light-blue-200 text-xl mb-2"> <Intl id="E2EForgeryDetection"></Intl> </div>
                            <p className="text-gray-300 text-base">
                            <Intl id="E2EForgeryDetectionExplanation" />
                            </p>
                        </div>
                        <div className="px-6 pt-4 pb-2">
                            <Link to='#' className="mt-3 text-blue-500 inline-flex items-center"><Intl id="formore"></Intl>
                                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                                        </svg>
                            </Link>
                        </div>
                    </div>



                    

                    
            </div>
        </>
    )
}

export default Homecards
import React from 'react'

import Intl from '../../Intl'
import aboutVideo from '../../../assets/videos/video1.mp4'

function HomeAboutCompany() {
    return (
        <>
            <div className="container mt-10 mx-auto flex-row">

                <div className="flex bg-gray-800 container  md:flex-row md:flex-nowrap flex-wrap flex-col shadow-2xl">

                    <div className="lg:w-1/2 w-full overflow-hidden">
                        {/* <img alt="feature" className="object-cover object-center h-full w-full" src={aboutImage} /> */}

                        <video autoPlay loop muted className='object-cover object-center h-full w-full'>
                            <source src={aboutVideo} type='video/mp4' />
                        </video>
                    </div>



                    <div className="flex flex-col font-medium flex-wrap lg:py-6  lg:w-1/2 lg:pl-12 lg:text-left text-center">
                        <h3 className="text-2xl font-sans text-white mb-4"> <Intl id="AboutVermaGmbH"></Intl></h3>
                        <p className='text-gray-400'>
                            <Intl id="AboutVermaGmbHExplanation" ></Intl><br /> We work in state of the art technologies. We use containerized technologies, Python, serverless technologies etc.
                        </p>

                        <div className="flex mb-10 lg:items-start items-center p-2">
                            <div className="flex items-center justify-center rounded-full shadow-md p-3 m-3 bg-red-500">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6" viewBox="0 0 24 24">
                                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                                </svg>
                            </div> 
                            <div className="flex-grow px-3">
                                <h2 className="text-green-300 text-lg title-font font-medium"><Intl id="ContactUs"></Intl></h2>
                                <p className="leading-relaxed text-base">
                                    
                                    <div>
                                        support@avermass.de
                                    </div>
                                </p>

                            </div>
                        </div>


                        <div className="flex mb-10 lg:items-start items-center">
                            <div className="flex items-center justify-center rounded-full shadow-md p-3 m-3 bg-red-500">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6" viewBox="0 0 24 24">
                                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                                </svg>
                            </div>
                            <div className="flex-grow px-3">
                                <h2 className="text-green-300 text-lg title-font font-medium"><Intl id="location"></Intl></h2>
                                <p className="leading-relaxed text-base">
                                    VERMA GmbH <br /> Kirchgasse 6 <br /> 85653 Aying, Germany
                                </p>

                            </div>
                        </div>


                        <div className="flex mb-10 lg:items-start items-center">
                            <div className="flex items-center justify-center rounded-full shadow-md p-3 m-3 bg-red-500">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6" viewBox="0 0 24 24">
                                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                                </svg>
                            </div>
                            <div className="flex-grow px-3">
                                <h2 className="text-green-300 text-lg title-font font-medium"><Intl id="BusinessHours"></Intl></h2>
                                <p className="leading-relaxed text-base">
                                Mon - Fri (9:00 AM to 8:00 PM)  <br />
                                Sat - Sun (closed)
                                </p>

                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </>
    )
}

export default HomeAboutCompany
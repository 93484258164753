import React, { useEffect, useState, useRef, useContext } from "react";
import { CarouselData as images } from "./CarouselData";
import GlobalContext from "../../../context/GlobalContext";
import Intl from '../../Intl';  
import { navigate } from '@reach/router'                             

const Carousel = ({
    data,
    delay,
    slide,
    dark,
    width,
    height
}) => {
    const gContext = useContext(GlobalContext);
    const [current, setCurrent] = useState(0);
    const autoPlayRef = useRef();

    const getStarted = () => {
        //const data = { refresh: gContext.refreshToken };

        if (!gContext.isLoggedIn){
            navigate('/app/login');
        } else {
            navigate('/app/dashboard');
        }
    }


    useEffect(() => {
        autoPlayRef.current = right;
    });

    useEffect(() => {
        const play = () => {
            autoPlayRef.current();
        };

        if (delay !== null && slide) {
            const interval = setInterval(play, delay);
            return () => clearInterval(interval);
        }
    }, [delay, slide]);

    const len = data.length;

    // const left = () => setCurrent(current===0?len-1:current-1);
    const right = () => setCurrent(current === len - 1 ? 0 : current + 1);
    const darkStyle = {
        backgroundColor: "black",
    };
    const carouselStyle = {
        width: width,
        height: height,
    }

    if (!Array.isArray(data) || data.length <= 0) {
        return null;
    }

    return (
        <section className="flex relative" style={dark ? darkStyle : null}>


        <div className="z-10 title-font absolute  mt-36 left-28 flex opacity-90 text-5xl font-medium text-avermass-blue-600 md:flex-row md:flex-nowrap flex-wrap flex-col">
          {/* Welcome to  Forgery Detection Service */}
            {/* <Intl id="welcomeToForgeryDetectionService"/> */}
                Welcome to  FDS and ScrumBot
        </div>

        <div className="z-10 absolute  mt-56 left-28 opacity-90 text-2xl font-sans text-white md:flex-row md:flex-nowrap flex-wrap flex-col md:flex hidden ">
          {/* We stop rumors and present the truth behind every story. */}

            <Intl id="weStopRumorsAndPresentTheTruthBehindEveryStory"/>
        
        </div>

        <div onClick={()=>{getStarted()}} className="inline-flex border-gray-500 bg-avermass-blue-500 z-10 absolute  py-2 px-8 focus:outline-none justify-center mt-72 font-sans  left-28  opacity-90 cursor-pointer   text-white hover:bg-avermass-blue-900 rounded text-lg transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300 md:flex-row md:flex-nowrap flex-wrap flex-col">
          {/* Get Started */}
        
            <Intl id="getStarted"/>
            

        </div>



            {/* images */}
            {images.map((image, index) => {
                return (
                    <div
                        className={
                            index === current
                                ? "w-full h-1/4 transition ease-in  opacity duration-500 scale-150 block"
                                : "opacity-0 transition ease-in hidden"
                        }
                        key={index}
                    >
                        {
                            <img
                                src={image.image}
                                alt="mountain pictures"
                                className="relative flex justify-center items-center w-full h-full opacity-4"
                                style={carouselStyle}
                            />
                        }
                    </div>
                );
            })}
        </section>
    );
};

Carousel.defaultProps = {
    delay: 5000,
    width: 1800,
    height: 470,
};

export default Carousel;

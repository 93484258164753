import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineVerticalRight, AiOutlineVerticalLeft } from 'react-icons/ai';
import useTranslate from '../../../hooks/useTranslate';
import Intl from '../../Intl';

let count = 0;
let slideInterval;
export default function HomeCustomer() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const [translate] = useTranslate();
  const featuredProducts = translate('featuredProducts', { isArray: true });
  
  const slideRef = useRef();

  const removeAnimation = () => {
    slideRef.current.classList.remove('fade-anim');
  };

  useEffect(() => {
    slideRef.current.addEventListener('animationend', removeAnimation);
    slideRef.current.addEventListener('mouseenter', pauseSlider);
    slideRef.current.addEventListener('mouseleave', startSlider);

    startSlider();
    return () => {
      pauseSlider();
    };
    // eslint-disable-next-line
  }, []);

  const startSlider = () => {
    slideInterval = setInterval(() => {
      handleOnNextClick();
    }, 30000);
  };

  const pauseSlider = () => {
    clearInterval(slideInterval);
  };

  const handleOnNextClick = () => {
    count = (count + 1) % featuredProducts?.length;
    setCurrentIndex(count);
    // slideRef.current.classList.add("fade-anim");
  };
  const handleOnPrevClick = () => {
    const productsLength = featuredProducts?.length;
    count = (currentIndex + productsLength - 1) % productsLength;
    setCurrentIndex(count);
    // slideRef.current.classList.add("fade-anim");
  };

  return (
    <>
      <div className='flex justify-center text-center'>
        <h1 className='text-white text-center text-3xl font-sans'>
          <Intl id='whatour'></Intl>{' '}
          <span className='text-blue-800'>
            <Intl id='customer'></Intl>
          </span>{' '}
          <Intl id='say'></Intl>
        </h1>
      </div>

      <div
        ref={slideRef}
        className='w-full select-none relative flex items-center md:flex-row md:flex-nowrap flex-wrap flex-col'
      >
        <div className='aspect-w-14 aspect-h-9  p-20'>
          <div className='w-full flex justify-center  text-white absolute md:flex-row md:flex-nowrap flex-wrap flex-col'>
            ❝ {featuredProducts[currentIndex]?.body} ❞
          </div>
          <div className=' w-full items-center flex justify-center font-medium text-xl  text-white text-center mt-20 absolute'>
            {' '}
            👉 {featuredProducts[currentIndex]?.title}
          </div>
        </div>
        <div className="absolute m-10 pr-16 top-1/2 transform -translate-y-1/2 px-3 w-full flex justify-between items-center md:flex-row md:flex-nowrap flex-wrap flex-col">
          <button
            className="bg-black text-white p-1 rounded-full bg-opacity-50 cursor-pointer hover:bg-opacity-100 transition"
            onClick={handleOnPrevClick}
          >
            <AiOutlineVerticalRight size={20} />
          </button>

          <button
            className="bg-black text-white p-1 rounded-full bg-opacity-50 cursor-pointer hover:bg-opacity-100 transition"
            onClick={handleOnNextClick}
          >
            <AiOutlineVerticalLeft size={20} />
          </button>

        </div>
      </div>
    </>
  );
}

import Img from '../../../assets/images/carusol/tech7.jpeg';
import Img2 from '../../../assets/images/carusol/tech5.jpeg';
import Img3 from '../../../assets/images/carusol/tech4.jpeg';
import Img4 from '../../../assets/images/carusol/tech2.jpeg';
import Img5 from '../../../assets/images/carusol/tech6.jpeg';



export const CarouselData = [
    
    {
        image: Img5  ,
    },
    {
        image: Img4 ,
    },
    {
        image: Img3  ,
    },
    {
        image: Img2 ,
    },
    {
        image: Img ,
    },
    
]